/* This file is for your main application css. */

h4 {
  margin-top: 0px;
}

.post-list-item {
  margin: 10px;
  border: 1px solid #EEEEEE;
  border-radius: 3px;
}

.black-link {
  color: #333;
}

.black-link:hover {
  color: #555;
  background-color: #000000;
}

.post-list-item-inner {
  margin: 10px;
}

.my-thumbnail {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}
